import { useCallback, useEffect, useState } from 'react';
import { HealthMonitorCodes } from '@binah/web-sdk';
import { AppErrorCode } from '../types';

const useInfo = (info) => {
  const [infoMessage, setInfoMessage] = useState<string>();

  const displayInstruction = useCallback((message: string) => {
    setInfoMessage(message);
  }, []);

  useEffect(() => {
    if (info?.code === -1) {
      setInfoMessage('');
      return;
    }

    switch (info?.code) {
      case HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING:
        displayInstruction(`Warning: ${info.code}`);
        break;
      case AppErrorCode.MEASUREMENT_CODE_FACE_UNDETECTED_ERROR:
        displayInstruction('Face not detected');
        break;
    }
  }, [info]);

  return infoMessage;
};
export default useInfo;
