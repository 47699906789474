import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {HealthMonitorCodes, SessionState, VitalSigns} from '@binah/web-sdk';
import { useMediaPredicate } from 'react-media-hook';
import {
  useError,
  useInfo,
  useLicenseKey,
  useMeasurementDuration,
  useMonitor,
  usePageVisibility,
  usePrevious,
  useWarning,
} from '../hooks';
import Stats from './Stats';
import StartButton from './StartButton';
import { mirror } from '../style/mirror';
import { Flex } from './shared/Flex';
import Timer from './Timer';
import media from '../style/media';
import InfoBar from './InfoBar';
import { ErrorAlert, InfoAlert, WarningAlert } from './alert';
import Loader from './Loader';
import { VideoReadyState } from '../types';
import TopBar from './TopBar';
import Mask from '../assets/mask.svg';
import CountDownTimer from "./CountDownTimer";
import SubmitButton from "./SubmitButton";

const MonitorWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 7.55;
  ${media.tablet`
    height: 100%;
  `}
`;

const VideoAndStatsWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  width: 100%;
  ${media.tablet`
    width: 812px;
    height: 609px;
  `}
  ${media.wide`
    width: 1016px;
    height: 762px;
  `}
`;

const VideoWrapper = styled.div`
  position: relative;
  width: auto;
  height: inherit;
  z-index: -1;
`;

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  ${media.desktop`
      object-fit: contain;
  `}
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${media.desktop`
      object-fit: contain;
  `}
  ${mirror}
`;

const ButtonWrapper = styled(Flex)`
  flex: 2;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 18px;
  ${media.tablet`
    padding: 0;
    height: auto;
    width: auto;
    position: absolute;
    right: 0;
    margin-right: 60px;
  `}
`;

const ButtomTimerWrapper = styled(Flex)`
  display: none;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

const InfoBarWrapper = styled.div`
  flex: 0.45;
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const BinahMonitor = ({
  showMonitor,
  cameraId,
  onLicenseStatus,
  onSettingsClick,
}) => {
  if (!showMonitor) {
    return null;
  }

  const video = useRef<HTMLVideoElement>(null);
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState<boolean>(
    false,
  );
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isPageVisible = usePageVisibility();
  const isMediaTablet = useMediaPredicate('(min-width: 1000px)');
  const [processingTime] = useMeasurementDuration();
  const [licenseKey] = useLicenseKey();
  const [isHasResults, setHasResults] = useState<boolean>(false);
  const [allVitalSigns, setAllVitalSigns] = useState<VitalSigns>(null);

  const onResults = (vitalSigns: VitalSigns) => {
    setHasResults(true);
    setAllVitalSigns(vitalSigns);
  }

  const {
    sessionState,
    vitalSigns,
    offlineMeasurements,
    error,
    warning,
    info,
  } = useMonitor(
    video,
    cameraId,
    processingTime,
    licenseKey,
    null,
    startMeasuring,
    onResults
  );
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);
  const infoMessage = useInfo(info);

  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState],
  );

  const isVideoReady = useCallback(
    () => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA,
    [],
  );

  const handleButtonClick = useCallback(() => {
    if (sessionState === SessionState.ACTIVE) {
      setIsLoading(true);
      setStartMeasuring(true);
      setHasResults(false);
    } else if (isMeasuring()) {
      setStartMeasuring(false);
    }
  }, [sessionState]);

  const handleSubmitButtonClick = useCallback(() => {
    let url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href;
    window.parent.postMessage(allVitalSigns, url);
  }, [allVitalSigns]);

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      } else {
        setIsMeasurementEnabled(true);
      }
      !isPageVisible && setStartMeasuring(false);
    } else if (
      (sessionState === SessionState.ACTIVE ||
        sessionState === SessionState.TERMINATED) &&
      errorMessage
    ) {
      setIsMeasurementEnabled(false);
    }
    if (
      sessionState === SessionState.ACTIVE &&
      prevSessionState !== sessionState
    ) {
      setStartMeasuring(false);
    }
  }, [errorMessage, sessionState, isPageVisible]);

  useEffect(() => {
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);

  return (
    <>
      {/*<TopBar onSettingsClick={onSettingsClick} isMeasuring={isMeasuring()} />*/}
      <MonitorWrapper>
        <MeasurementContentWrapper>
          <InfoBarWrapper>
            <InfoBar
              showTimer={isMeasurementEnabled && !isMediaTablet}
              isMeasuring={isMeasuring()}
              durationSeconds={processingTime}
              offlineMeasurements={offlineMeasurements}
            />
          </InfoBarWrapper>
          <VideoAndStatsWrapper>
            <VideoWrapper>
              <Img src={Mask} />
              <Video ref={video} id="video" muted={true} playsInline={true} />
            </VideoWrapper>
            {(isMeasuring()
              ? !errorMessage && !warningMessage
              : !errorMessage) &&
              isMeasurementEnabled && <Stats vitalSigns={vitalSigns} />}
            <ErrorAlert message={errorMessage} />
            {isMeasuring() && <WarningAlert message={warningMessage} />}
            {isMeasuring() && <InfoAlert message={infoMessage} />}
            {!isVideoReady() && licenseKey && <Loader />}
          </VideoAndStatsWrapper>
          <ButtomTimerWrapper>
            {isMeasurementEnabled && (
              <CountDownTimer started={isMeasuring()} durationSeconds={processingTime}/>
            )}
          </ButtomTimerWrapper>
        </MeasurementContentWrapper>
        <ButtonWrapper>
          <StartButton
            isLoading={isLoading}
            isMeasuring={isMeasuring()}
            onClick={handleButtonClick}
          />
          {isHasResults && <SubmitButton onClick={handleSubmitButtonClick}/>}
        </ButtonWrapper>
      </MonitorWrapper>
    </>
  );
};

export default BinahMonitor;
