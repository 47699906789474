import React from 'react';
import styled from 'styled-components';
import Play from '../assets/play-big.svg';
import Stop from '../assets/stop.svg';
import media from '../style/media';
import Spinner from './Spinner';

const Button = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  background-color: #01061b;
  border-radius: 10%;
  cursor: pointer;
  //height: 58px;
  //width: 58px;
  ${media.tablet`
    height: 80px;
    width: 150px;
`}
`;

const TextBold = styled.span`
  font-weight: bold;
  padding: 5px;
`;

const Icon = styled.img`
  width: 100%;
  padding: 52px;
  ${media.tablet`
    padding: 32px;
  `}
`;

export interface ISubmitButton {
  /**
   *  function triggered on button click
   */
  onClick: () => void;
}

const SubmitButton = ({ onClick }: ISubmitButton) => {
  return (
    <>
      {<Button onClick={onClick}>
        <TextBold>Submit measurements</TextBold>
      </Button>}
    </>
  );
};

export default SubmitButton;
